<template>
  <div v-if="this.companyDetails" class="company-card">
    <img :src="this.companyDetails.logoURL" alt="Company image" class="company-logo-image" />
    <h2 class="name">{{ this.companyDetails.name }}</h2>
    <p class="formattedAddress">{{ this.companyDetails.address }}</p>
    <p class="secondaryAddress">
      {{
        this.companyDetails.zipCode +
        ", " +
        this.companyDetails.city +
        ", " +
        this.companyDetails.state
      }}
    </p>
  </div>
</template>

<script>
// import { retail_shops_DB } from "@/firebase";
export default {
  name: "CompanyDetails",
  props: {
    companyDetails: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped>
@import "./CompanyDetails.css";
</style>
