<template>
  <div>
    <div class="top-container">
      <p class="title">Gestisci automaticamente le candidature</p>
      <div class="subtitle-container">
        <p class="subtitle">
          Ricevi un'email non appena qualcuno si candida presso il tuo punto
          vendita.
        </p>
        <img
          class="subtitle-image"
          src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fhr-managers-searching-new-employee.png?alt=media&token=49ffeeda-99b7-47dd-a9f6-4621178e6e6f"
          alt="subtitle image"
        />
      </div>
    </div>
    <div class="see-retails-container">
      <button @click="seeRetails()" class="see-retails-button">
        Vedi i negozi già presenti
      </button>
    </div>
    <div class="retail-product-container">
      <div class="products-intro">
        <h1 class="products-title">Ricevi un codice QR per il tuo negozio</h1>
        <description-card :description="description1" />
        <description-card :description="description2" />
        <description-card :description="description3" />
      </div>
      <img
        class="qr-code-image"
        src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fgirl-is-paying-through-scanner.png?alt=media&token=0321bd66-34ea-4d3e-81cc-299a291adbae"
        alt="QR Code illustration"
      />
    </div>
    <div class="section-1-container">
      <p class="price">A soli 9 Euro al mese</p>
      <div class="features-container">
        <feature-card :feature="feature1" />
        <feature-card :feature="feature2" />
        <feature-card :feature="feature3" />
      </div>
    </div>
    <div class="section-2-container">
      <p class="title">Inizia</p>
      <div class="subtitle-container" id="chooseProduct">
        <p class="subtitle">
          Scegli il formato che più si adatta al tuo negozio.
        </p>
        <img
          class="subtitle-image-2"
          src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fcursor.png?alt=media&token=8d27f012-9e14-442a-a669-732b81c99cac"
          alt="cursor image"
        />
      </div>
      <div class="products-container">
        <product-card :product="piccolo" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "./ProductCard.vue";
import DescriptionCard from "./DescriptionCard.vue";
import FeatureCard from "./FeatureCard.vue";

export default {
  components: {
    ProductCard,
    DescriptionCard,
    FeatureCard,
  },
  name: "RetailProducts",
  data() {
    return {
      description1: {
        title: "Codice-QR adesivo",
        description:
          "Grazie al nostro servizio chiunque voglia mandare il CV per lavorare nella tua attività potrà inquadrare il codice e inviare la candidatura sulla tua email.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fqr-code(1).png?alt=media&token=0488c233-89f7-48ac-b01c-b1d8e8ce1cbe",
      },
      description2: {
        title: "Più CV",
        description:
          "Avrai accesso a candidature extra di profili con i medesimi requisiti richiesti da te, nella zona in cui ti trovi.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fcv.png?alt=media&token=cecdaa12-7ba4-4ed3-bcce-8d5415e22845",
      },
      description3: {
        title: "Visibilità",
        description:
          "Sarai visibile su tutto il territorio nazionale e potrai ricevere candidature da tutta Italia.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fmagnifying-glass.png?alt=media&token=36477bc5-7ab3-459a-85e6-4990941cf158",
      },

      feature1: {
        title: "Codice-QR adesivo",
        description:
          "Grazie al nostro servizio chiunque voglia mandare il CV per lavorare nella tua attività potrà inquadrare il codice e inviare la candidatura sulla tua email.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fqr-code(1).png?alt=media&token=0488c233-89f7-48ac-b01c-b1d8e8ce1cbe",
      },
      feature2: {
        title: "Più CV",
        description:
          "Avrai accesso a candidature extra di profili con i medesimi requisiti richiesti da te, nella zona in cui ti trovi.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fcv.png?alt=media&token=cecdaa12-7ba4-4ed3-bcce-8d5415e22845",
      },
      feature3: {
        title: "Visibilità",
        description:
          "Sarai visibile su tutto il territorio nazionale e potrai ricevere candidature da tutta Italia.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fitaly.png?alt=media&token=c7a88071-b8b6-4c74-9f03-bdf2bfc1f27a",
      },

      piccolo: {
        title: "Piccolo 10 x 20 cm",
        description:
          "Ideale da esibire alla cassa o da esporre all'interno di una locandina già presente in vetrina.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2FPiccolo%20Negozi.png?alt=media&token=5d343a00-cb5a-444f-97ed-2c36a60214c9",
        link: "/company-details",
        showButton: true,
      },
    };
  },
  methods: {
    scrollToTargetDiv() {
      const targetDiv = document.getElementById("chooseProduct");
      targetDiv.scrollIntoView();
    },
    seeRetails() {
      this.$router.push({
        name: "ListOfRetailShops",
      });
    },
  },
};
</script>

<style scoped>
@import "./RetailProducts.css";
</style>
