<template>
  <div>
    <section class="contact-section">
      <div class="contact-form-container">
        <h2 class="contact-us-title">Contact Us</h2>
        <p class="contact-us-subtitle">
          Feel free to contact us at any time. We will get back to you as soon
          as possible.
        </p>
        <form class="contact-form" @submit.prevent="sendInquiry">
          <div class="form-group">
            <input
              type="text"
              id="name"
              class="form-control"
              required
              :placeholder="'Name'"
              v-model="form.name"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              id="email"
              class="form-control"
              required
              :placeholder="'Email'"
              v-model="form.email"
            />
          </div>
          <div class="form-group">
            <textarea
              id="message"
              class="form-control"
              rows="3"
              required
              :placeholder="'Message'"
              v-model="form.message"
            ></textarea>
          </div>
          <button type="submit" class="btn btn-primary">Invia messaggio</button>
        </form>
      </div>
      <div class="company-info-container">
        <p class="contacts-company-title">Talketing Ltd</p>
        <p class="company-email">
          <i class="fas fa-envelope" aria-hidden="true"></i>
          support@talketing.ai
        </p>
        <p class="company-address">
          <i class="fas fa-map-pin" aria-hidden="true"></i>
          International House, 61 Mosley Street, M2 3HZ, Manchester, UK
        </p>
        <div class="social-container">
          <img src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Flinkedin.svg?alt=media&token=09f0d9c3-87d5-4c3f-a101-2b205c51482f" alt="LinkedIn" class="social-icon" />
          <img src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Finstagram.svg?alt=media&token=db84d092-14c2-4e62-ad20-b216f385d9ac" alt="Instagram" class="social-icon" />
          <img src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Ftiktok.svg?alt=media&token=b9be6b4b-e15c-4835-8b60-0da1b4acbbd8" alt="TikTok" class="social-icon" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactsPage",

  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },

  methods: {
    sendInquiry() {
      const apiUrl = "/api/contact-inquiries";
      const data = this.form;

      axios
        .post(apiUrl, data)
        .then(() => {
          this.$toast({
            title: "Successo",
            message:
              "Il tuo messaggio è stato inviato. Riceverai una risposta al più presto.",
            type: "success",
          });

          this.clearForm();
        })
        .catch((error) => {
          console.error(error);
          this.$toast({
            title: "Errore",
            message: "Si è verificato un errore durante l'invio del messaggio.",
            type: "error",
          });
        });
    },

    clearForm() {
      this.form.name = "";
      this.form.email = "";
      this.form.message = "";
    },
  },
};
</script>

<style scoped>
@import "./ContactsPage.css";
</style>
