import posthog from 'posthog-js';

const PosthogPlugin = {
  install(Vue) {
    Vue.prototype.$posthog = posthog.init(
      "phc_3zHF7mL0985VC4QKMARtvIEX53PQ39uIEV8LBzMGIY1",
      {
        api_host: "https://eu.posthog.com"
      }
    );
  }
};

export default PosthogPlugin;