<!-- BackButton.vue -->
<template>
  <button @click="navigateBack" class="back-button">
    <i class="fas fa-chevron-left"></i> {{ pathName }}
  </button>
</template>

<script>
export default {
  props: {
    path: String, // Path to navigate back
    pathName: String, // Display name for the path
  },
  methods: {
    navigateBack() {
      // Use router to navigate back to the specified path
      this.$router.push({ path: this.path });
    },
  },
};
</script>

<style scoped>
.back-button {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: black; /* Blue color, you can customize */
  display: flex;
  align-items: center;
  font-weight: bold;
}

.back-button i {
  margin-right: 5px;
}
</style>
