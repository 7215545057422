<template>
  <div v-if="this.companyDetails" class="company-card">
    <p class="title">Vuoi lavorare qui?</p>
    <p class="subtitle">Carica qui il tuo CV</p>
    <div class="apply-job-container">
      <a @click="applyNow()" class="job-apply-button">
        <i class="fas fa-file-pdf"></i>
        Carica CV
      </a>
      <input
        class="selected-file-name"
        type="file"
        id="jobApplyFile"
        accept="application/pdf"
      />
      <label v-if="selectedFileName" class="file-name-label">
        {{ selectedFileName }}
      </label>
    </div>

    <div class="send-application-container">
      <div class="termsOfUse-container">
        <input
          type="checkbox"
          id="termsOfUse"
          required
          @change="handleTermsOfUseChange"
        />
        <label for="termsOfUse">Accetto</label>
      </div>
      <label class="label-privacy"
        >Accetto i Termini e le condizioni d'uso</label
      >
      <div class="privacy-container">
        <input
          type="checkbox"
          id="privacy"
          required
          @change="handlePrivacyChange"
        />
        <label for="privacy">Autorizzo</label>
      </div>
      <label class="label-privacy"
        >Autorizzo il trattamento dei miei dati personali ai sensi del Dlgs 196
        del 30 giugno 2003 e dell'art. 13 GDPR</label
      >
      <input
        type="text"
        id="nameInput"
        placeholder="email@example.com"
        class="email-input"
        @input="validateEmailInput"
      />
      <button
        @click="sendCV"
        :disabled="!isSendButtonEnabled || isLoading"
        class="send-application-button"
      >
        <span v-if="!isLoading">Invia CV</span>
        <span v-if="isLoading">Loading...</span>
      </button>
    </div>
  </div>
</template>

<script>
import { storage, retail_shops_applications_DB } from "../firebase";
import { ref as dbRef, onValue } from "firebase/database";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import axios from "axios";
// import { retail_shops_DB } from "@/firebase";
export default {
  name: "RetailQRCode",
  props: {
    ownerDetails: {
      type: Object,
      required: true,
    },
    companyDetails: {
      type: Object,
      required: true,
    },
    companyKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: null,
      cvFile: null,
      selectedFileName: null,
      cvUploaded: false,
      termsOfUseAccepted: false,
      privacyAccepted: false,
      isSendButtonEnabled: false,
      sendApplicationButton: null, // Initialize the button reference,
      isLoading: false,
    };
  },
  watch: {
    email() {
      this.updateSendApplicationButtonState();
    },
    cvUploaded() {
      this.updateSendApplicationButtonState();
    },
    termsOfUseAccepted() {
      this.updateSendApplicationButtonState();
    },
    privacyAccepted() {
      this.updateSendApplicationButtonState();
    },
    isSendButtonEnabled(newVal) {
      if (this.sendApplicationButton) {
        // Update the button's disabled state
        this.sendApplicationButton.disabled = !newVal;
      }
    },
  },
  methods: {
    validateEmailInput(event) {
      const emailValue = event.target.value;
      const regex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(emailValue)) {
        this.email = emailValue;
      } else {
        this.email = null;
      }
    },
    handleTermsOfUseChange(event) {
      this.termsOfUseAccepted = event.target.checked;
    },
    handlePrivacyChange(event) {
      this.privacyAccepted = event.target.checked;
    },
    updateSendApplicationButtonState() {
      this.isSendButtonEnabled =
        this.cvUploaded &&
        this.termsOfUseAccepted &&
        this.privacyAccepted &&
        this.email;
    },
    applyNow() {
      // Open the hidden file input element using click()
      const fileInput = document.getElementById("jobApplyFile");
      fileInput.click();
      // Listen for the change event on the file input element
      fileInput.addEventListener("change", () => {
        const selectedFile = fileInput.files[0];
        if (selectedFile) {
          this.cvFile = selectedFile;
          this.selectedFileName = selectedFile.name;
          this.cvUploaded = true;
        } else {
          this.selectedFileName = null;
          this.cvUploaded = false;
        }
      });
    },
    async sendCV() {
      if (
        this.cvUploaded &&
        this.termsOfUseAccepted &&
        this.privacyAccepted &&
        this.email
      ) {
        // start loading button
        this.isLoading = true;
        try {
          // check if email already exists
          const emailExists = await this.checkIfEmailExists(this.email);
          if (!emailExists) {
            const path = `cvs-retail/${this.selectedFileName}`;
            // Create a reference to the CV file in Firebase Storage
            const storageRef = ref(storage, path);
            // Upload the CV file to Firebase Storage
            await uploadBytes(storageRef, this.cvFile);
            // Get the download URL for the uploaded file
            const downloadURL = await getDownloadURL(storageRef);

            const payload = {
              companyKey: this.companyKey,
              downloadURL,
              path,
              senderEmail: this.email,
              ownerDetails: this.ownerDetails,
              companyDetails: this.companyDetails,
            };
            // Update the Realtime Database with the CV file information
            await this.callServerFunction(payload);
            // stop loading button
            this.isLoading = false;
            alert("Application submitted successfully!");
            this.$router.push({
              name: "ListOfRetailsShops",
            });
          } else {
            alert("You already applied with this email.");
          }
          console.log("emailExists FALSE");
          this.isLoading = false;
        } catch (error) {
          alert(error);
          // stop loading button
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
    async checkIfEmailExists(senderEmail) {
      const applicationsRef = dbRef(
        retail_shops_applications_DB,
        `/shops-applications/${this.companyKey}`
      );
      return new Promise((resolve) => {
        onValue(applicationsRef, (snapshot) => {
          const applicationsData = snapshot.val();

          if (!applicationsData) {
            // If applicationsRef doesn't exist or is empty
            resolve(false);
            return;
          }

          for (const applicationKey in applicationsData) {
            if (applicationsData[applicationKey] === senderEmail) {
              resolve(true);
              return;
            }
          }

          // If no matching email is found
          resolve(false);
        });
      });
    },
    async callServerFunction(payload) {
      try {
        return await axios.post(
          "https://us-central1-talketing.cloudfunctions.net/sendCVToRetailShopOwner",
          payload,
          {
            headers: {
              "Content-Type": "application/json", // Set the content type
            },
          }
        );
      } catch (error) {
        // Handle errors
        console.error("error with sendCVToRetailOwner: ", error);
        throw error;
      }
    },
  },
};
</script>

<style scoped>
@import "./RetailQRCode.css";
</style>
