<!-- JobContainer.vue -->
<template>
  <div class="job-container">
    <p class="job-title-custom">{{ jobData.title }}</p>
    <p v-if="jobData.addresses && jobData.addresses[0]" class="job-address">
      {{ jobData.addresses[0] }}
    </p>
    <p v-if="jobData.companyDisplayName" class="job-city">
      {{ jobData.companyDisplayName }}
    </p>
    <button @click="goToJobListing" class="view-job-button">See job</button>
  </div>
</template>

<script>
export default {
  props: {
    jobData: Object, // Pass the job data as a prop
  },
  methods: {
    goToJobListing() {
      this.$emit("go-to-job-listing", this.jobData);
    },
  },
};
</script>

<style scoped>


.job-container {
  background-color: white;
  padding: 20px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  /* align-content: center; */
  justify-content: space-between;
  gap: 30px;
}

.job-title-custom {
  flex: 1;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  text-decoration: none;
}

.job-address {
  flex: 1;
  font-family: Arial, Helvetica, sans-serif;
  color: darkgray;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
}

.job-city {
  flex: 1;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
}

.job-description {
  flex: 1;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
}

.job-domain {
  flex: 1;
  /* background-color: yellow; */
  font-size: 12px;
  color: gray;
  cursor: pointer;
  font-weight: normal;
  max-width: 200px;
}

.view-job-button {
  flex: 1;
  font-size: 15px;
  padding: 10px 20px;
  background-color: #1855b4ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
</style>
