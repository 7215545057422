// store/index.js
import Vue from "vue";
import Vuex from "vuex";
import { localStoragePlugin } from "./localstorage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    job: null,
  },
  mutations: {
    setJob(state, job) {
      state.job = job;
    },
  },
  plugins: [localStoragePlugin],
});
