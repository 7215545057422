<template>
  <div>
    <div class="content-container-2">
      <MainSearchBar @search-input-changed="this.handleSearchInput" />
      <div v-if="queried_jobs.length > 0" class="jobs-main-container">
        <PaginationComponent
          pagination
          :max-results-per-page="maxResultsPerPage"
          :current-results="currentResults"
          :total-results="totalResults"
          :previous-results="previousResults"
          :next-results="nextResults"
        />
        <div class="job-container-parent">
          <JobContainer
            v-for="item in queried_jobs"
            :key="item.job.name"
            :job-data="item.job"
            @go-to-job-listing="goToJobListing"
          />
        </div>
        <PaginationComponent
          pagination
          :max-results-per-page="maxResultsPerPage"
          :current-results="currentResults"
          :total-results="totalResults"
          :previous-results="previousResults"
          :next-results="nextResults"
        />
      </div>
      <div v-if="isLoading">
        <div class="loading-overlay">
          <div class="loading-spinner">
            <span class="loading-spinner-dot"></span>
            <span class="loading-spinner-dot"></span>
            <span class="loading-spinner-dot"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainSearchBar from "./MainSearchBar.vue";
import PaginationComponent from "./PaginationComponent.vue"; // Update the path accordingly
import JobContainer from "./JobContainer.vue";
import axios from "axios";

export default {
  components: {
    MainSearchBar,
    PaginationComponent,
    JobContainer,
    // RouterLink,
  },
  name: "JobsListing",
  data() {
    return {
      maxResultsPerPage: 20,
      currentResults: 0, // Set the initial current page
      totalResults: 0, // Set the total number of pages
      queried_jobs: [], // Here we will store our companies data fetched from Firebase
      isLoading: false,
      search_term: "",
      offset: 0,
    };
  },
  mounted() {
    this.fetchJobsFrontEnd();
  },
  methods: {
    async previousResults() {
      this.offset = this.totalResults - this.currentResults;
      if (this.currentResults > this.maxResultsPerPage) {
        this.currentResults -= this.maxResultsPerPage;
      }
      return await this.fetchJobsFrontEnd();
    },
    async nextResults() {
      this.offset = this.currentResults;
      this.currentResults +=
        this.currentResults + this.maxResultsPerPage < this.totalResults
          ? this.maxResultsPerPage
          : this.totalResults - this.currentResults;
      return await this.fetchJobsFrontEnd();
    },
    async fetchJobsFrontEnd() {
      this.isLoading = true;
      try {
        this.queried_jobs = await this.fetchJobs(this.search_term, this.offset);
        this.isLoading = false;
      } catch (error) {
        alert("No job found: ", error);
        this.isLoading = false;
      }
    },
    async fetchJobs(search_term, offset) {
      try {
        const body = {
          search_term,
          offset,
        };
        const list = await axios.post(
          "https://us-central1-talketing.cloudfunctions.net/cts_queryJobs",
          body,
          {
            headers: {
              "Content-Type": "application/json", // Set the content type
            },
          }
        );
        if (this.currentResults == 0) {
          this.currentResults += list.data.matchingJobs.length;
        }
        this.totalResults = list.data.totalSize;
        return list.data.matchingJobs;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    async handleSearchInput(searchTerm) {
      // Update the searchTerm property
      this.search_term = searchTerm;
      this.currentResults = 0;
      this.totalResults = 0;
      this.offset = 0;
      await this.fetchJobsFrontEnd();
    },
    goToJobListing(job) {
      this.isLoading = true;
      this.$store.commit('setJob', job);
      this.$router.push({
        name: "ApplyJob"
      });
    },
  },
};
</script>

<style scoped>
@import "./LoadingOverlay.css";

.content-container-2 {
  position: relative; /* Position the container relative to its parent */
  min-height: 1000px;
  overflow: hidden;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.job-container-parent {
  min-height: 1000px;
  /* overflow-y: auto; */
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 20px;
}

.jobs-main-container {
  /* max-height: 700px; */
  /* overflow-y: auto; */
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 20px;
}
</style>
