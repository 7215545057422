<template>
  <div>
    <div class="section-0-container">
      <p class="title">Inserisci i tuoi dati</p>
      <div class="top-content-container">
        <div class="form-container">
          <p class="form-title">I tuoi dati</p>
          <p class="subtitle">Nome *</p>
          <input
            ref="ownerName"
            type="text"
            class="input"
            id="ownerName"
            placeholder="Nome"
            required
            @input="onOwnerNameChange"
          />
          <p class="subtitle">La tua email *</p>
          <input
            ref="ownerEmail"
            type="text"
            class="input"
            id="ownerEmail"
            placeholder="example@email.com"
            required
            @input="onOwnerEmailChange"
          />
          <p class="form-title">Dove inviare le candidature?</p>
          <p class="form-note">
            Inserisci un indirizzo e-mail a cui inviare tutte le candidature
            ricevute attraverso il codice QR.
          </p>
          <p class="subtitle">Email *</p>
          <input
            ref="forwardCVEmail"
            type="text"
            class="input"
            id="forwardCVEmail"
            placeholder="example@email.com"
            required
            @input="onForwardCVEmailChange"
          />
          <p class="form-title">Dove spedire il codice QR?</p>
          <div class="checkbox-container">
            <input
              ref="shopAddress"
              type="checkbox"
              id="shopAddress"
              required
              @change="onShippingCheckboxChange"
            />
            <label for="shopAddress">Lo stesso indirizzo del negozio.</label>
          </div>
          <p class="subtitle">Indirizzo *</p>
          <input
            ref="shippingAddress"
            class="input"
            type="text"
            id="shippingAddress"
            placeholder="Indirizzo"
            required
            @input="onShippingAddressChange"
          />
          <div class="zip-city-container">
            <div class="title-input-container">
              <p class="subtitle">CAP *</p>
              <input
                ref="shippingZipCode"
                class="input"
                type="text"
                id="shippingZipCode"
                placeholder="CAP"
                required
                @input="onShippingZipCodeChange"
              />
            </div>
            <div class="title-input-container">
              <p class="subtitle">Città *</p>
              <input
                ref="shippingCity"
                class="input"
                type="text"
                id="shippingCity"
                placeholder="Città"
                required
                @input="onShippingCityChange"
              />
            </div>
          </div>
          <p class="subtitle">Provincia *</p>
          <input
            ref="shippingState"
            class="input"
            type="text"
            id="shippingState"
            placeholder="Provincia"
            required
            @input="onShippingStateChange"
          />
          <StripeCheckoutButton
            :isButtonEnabled="this.isCheckoutButtonEnabled"
            :ownerDetails="this.ownerDetails"
            :companyDetails="this.companyDetails"
            :QRShippingAddress="this.QRShippingAddress"
            :product="this.product"
          />
        </div>
        <div class="recap-container">
          <CompanyDetails />
          <product-card :product="this.product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "./ProductCard.vue";
import CompanyDetails from "./CompanyDetails.vue";
import StripeCheckoutButton from "./StripeCheckoutButton.vue";

export default {
  components: {
    ProductCard,
    CompanyDetails,
    StripeCheckoutButton,
  },
  name: "Before_Checkout",
  data() {
    return {
      ownerDetails: null,
      product: null,
      companyDetails: null,
      QRShippingAddress: null,
      isCheckoutButtonEnabled: false,
      ownerName: null,
      ownerEmail: null,
      forwardCVEmail: null,
      shippingAddress: null,
      shippingZipCode: null,
      shippingCity: null,
      shippingState: null,
    };
  },
  mounted() {
    this.getProductFromStorage();
    this.getOwnerDetailsFromStorage();
    this.getCompanyDetailsFromStorage();
    this.getQRShippingAddressFromStorage();
  },
  watch: {
    ownerDetails() {
      // owner name
      this.ownerName = this.ownerDetails.name;
      this.$refs.ownerName.value = this.ownerDetails.name;
      // owner email
      this.ownerEmail = this.ownerDetails.email;
      this.$refs.ownerEmail.value = this.ownerDetails.email;
      // forward cv email
      this.forwardCVEmail = this.ownerDetails.forwardCVEmail;
      this.$refs.forwardCVEmail.value = this.ownerDetails.forwardCVEmail;
      this.enableNextButton();
    },
    QRShippingAddress() {
      // owner name
      this.shippingAddress = this.QRShippingAddress.address;
      this.$refs.shippingAddress.value = this.QRShippingAddress.address;
      // owner email
      this.shippingZipCode = this.QRShippingAddress.zipCode;
      this.$refs.shippingZipCode.value = this.QRShippingAddress.zipCode;
      // forward cv email
      this.shippingCity = this.QRShippingAddress.city;
      this.$refs.shippingCity.value = this.QRShippingAddress.city;

      this.shippingState = this.QRShippingAddress.state;
      this.$refs.shippingState.value = this.QRShippingAddress.state;
      this.enableNextButton();
    },
    ownerName() {
      this.enableNextButton();
    },
    ownerEmail() {
      this.enableNextButton();
    },
    forwardCVEmail() {
      this.enableNextButton();
    },
    shippingAddress() {
      this.enableNextButton();
    },
    shippingZipCode() {
      this.enableNextButton();
    },
    shippingCity() {
      this.enableNextButton();
    },
    shippingState() {
      this.enableNextButton();
    },
  },
  methods: {
    getProductFromStorage() {
      const productString = sessionStorage.getItem("product");
      if (productString) {
        let product = JSON.parse(productString);
        product.showButton = false
        this.product = product;
      }
    },
    getOwnerDetailsFromStorage() {
      const ownerDetailsString = sessionStorage.getItem("ownerDetails");
      if (ownerDetailsString) {
        const ownerDetails = JSON.parse(ownerDetailsString);
        this.ownerDetails = ownerDetails;
      }
    },
    getCompanyDetailsFromStorage() {
      const companyDetailsString = sessionStorage.getItem("companyDetails");
      if (companyDetailsString) {
        const companyDetails = JSON.parse(companyDetailsString);
        this.companyDetails = companyDetails;
      }
    },
    getQRShippingAddressFromStorage() {
      const QRShippingAddressString =
        sessionStorage.getItem("QRShippingAddress");
      if (QRShippingAddressString) {
        const QRShippingAddress = JSON.parse(QRShippingAddressString);
        this.QRShippingAddress = QRShippingAddress;
      }
    },
    onShippingCheckboxChange(event) {
      if (event.target.checked) {
        this.fillShippingWithCompanyAddress();
      } else {
        this.fillShippingWithQRAddress();
      }
    },
    fillShippingWithCompanyAddress() {
      if (this.companyDetails) {
        // address
        this.shippingAddress = this.companyDetails.address;
        this.$refs.shippingAddress.value = this.companyDetails.address;
        // zipcode
        this.shippingZipCode = this.companyDetails.zipCode;
        this.$refs.shippingZipCode.value = this.companyDetails.zipCode;
        // city
        this.shippingCity = this.companyDetails.city;
        this.$refs.shippingCity.value = this.companyDetails.city;
        // state
        this.shippingState = this.companyDetails.state;
        this.$refs.shippingState.value = this.companyDetails.state;
      }
      this.enableNextButton();
    },
    fillShippingWithQRAddress() {
      if (this.QRShippingAddress) {
        // address
        this.shippingAddress = this.QRShippingAddress.address;
        this.$refs.shippingAddress.value = this.QRShippingAddress.address;
        // zipcode
        this.shippingZipCode = this.QRShippingAddress.zipCode;
        this.$refs.shippingZipCode.value = this.QRShippingAddress.zipCode;
        // city
        this.shippingCity = this.QRShippingAddress.city;
        this.$refs.shippingCity.value = this.QRShippingAddress.city;
        // state
        this.shippingState = this.QRShippingAddress.state;
        this.$refs.shippingState.value = this.QRShippingAddress.state;
      } else {
        this.removeShippingAddress();
      }
      this.enableNextButton();
    },
    removeShippingAddress() {
      // address
      this.shippingAddress = null;
      this.$refs.shippingAddress.value = null;
      // zipcode
      this.shippingZipCode = null;
      this.$refs.shippingZipCode.value = null;
      // city
      this.shippingCity = null;
      this.$refs.shippingCity.value = null;
      // state
      this.shippingState = null;
      this.$refs.shippingState.value = null;

      this.enableNextButton();
    },
    onOwnerNameChange(event) {
      this.ownerName = event.target.value;
    },
    onOwnerEmailChange(event) {
      this.ownerEmail = event.target.value;
    },
    onForwardCVEmailChange(event) {
      this.forwardCVEmail = event.target.value;
    },
    onShippingAddressChange(event) {
      this.shippingAddress = event.target.value;
    },
    onShippingZipCodeChange(event) {
      this.shippingZipCode = event.target.value;
    },
    onShippingCityChange(event) {
      this.shippingCity = event.target.value;
    },
    onShippingStateChange(event) {
      this.shippingState = event.target.value;
    },
    enableNextButton() {
      this.saveData();
      this.isCheckoutButtonEnabled =
        this.ownerName &&
        this.ownerEmail &&
        this.forwardCVEmail &&
        this.shippingAddress &&
        this.shippingZipCode &&
        this.shippingCity &&
        this.shippingState
          ? true
          : false;
    },
    saveData() {
      const QRShippingAddress = {
        address: this.shippingAddress,
        zipCode: this.shippingZipCode,
        city: this.shippingCity,
        state: this.shippingState,
      };
      sessionStorage.setItem(
        "QRShippingAddress",
        JSON.stringify(QRShippingAddress)
      );
      const ownerDetails = {
        name: this.ownerName,
        email: this.ownerEmail,
        forwardCVEmail: this.forwardCVEmail,
      };
      sessionStorage.setItem("ownerDetails", JSON.stringify(ownerDetails));
    },
  },
};
</script>

<style scoped>
@import "./Before_Checkout.css";
</style>
