<template>
  <div>
    <BackButton :path="'/'" :pathName="'Jobs'" />
    <div class="job-container">
      <p class="job-company">{{ job.companyDisplayName }}</p>
      <a
        v-if="
          job.applicationInfo.uris[0] &&
          job.applicationInfo.uris[0] !== 'undefined'
        "
        :href="job.applicationInfo.uris[0]"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p class="job-title" style="text-decoration: underline">
          {{ job.title }}
        </p>
      </a>
      <a v-else>
        <p class="job-title">{{ job.title }}</p>
      </a>

      <p class="job-formattedDate">
        {{
          this.$convertUnixTimestampToRelativeDate(
            job.postingPublishTime.seconds
          )
        }}
      </p>
      <p class="job-address">{{ job.addresses[0] }}</p>
      <p v-if="job.employmentTypes" class="job-availability">
        {{ job.employmentTypes[0] }}
      </p>
      <p class="job-description">{{ job.description }}</p>

      <div class="apply-job-container">
        <a @click="applyNow()" class="job-apply-button">
          <i class="fas fa-file-pdf"></i>
          Carica CV
        </a>
        <input
          class="selected-file-name"
          type="file"
          id="jobApplyFile"
          accept="application/pdf"
        />
        <label v-if="selectedFileName" class="file-name-label">
          {{ selectedFileName }}
        </label>
      </div>
      <div class="send-application-container">
        <div class="termsOfUse-container">
          <input
            type="checkbox"
            id="termsOfUse"
            required
            @change="handleTermsOfUseChange"
          />
          <label for="termsOfUse">Accetto</label>
        </div>
        <label class="label-privacy"
          >Accetto i Termini e le condizioni d'uso</label
        >
        <div class="privacy-container">
          <input
            type="checkbox"
            id="privacy"
            required
            @change="handlePrivacyChange"
          />
          <label for="privacy">Autorizzo</label>
        </div>
        <label class="label-privacy"
          >Autorizzo il trattamento dei miei dati personali ai sensi del Dlgs
          196 del 30 giugno 2003 e dell'art. 13 GDPR</label
        >
        <input
          type="text"
          id="nameInput"
          placeholder="email@example.com"
          class="email-input"
          @input="validateEmailInput"
        />
        <button
          @click="sendCV()"
          :disabled="!this.isSendButtonEnabled"
          :enabled="this.isSendButtonEnabled"
          class="send-application-button"
        >
          Invia CV
        </button>
      </div>

      <div v-if="isLoading">
        <div class="loading-overlay">
          <div class="loading-spinner">
            <span class="loading-spinner-dot"></span>
            <span class="loading-spinner-dot"></span>
            <span class="loading-spinner-dot"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storage_cv, external_cv_DB } from "../firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { ref as dbRef, update, push } from "firebase/database";
import BackButton from "./BackButton.vue";

export default {
  components: {
    BackButton,
  },
  name: "ApplyJob",
  data() {
    return {
      job: this.$store.state.job,
      email: null,
      cvFile: null,
      selectedFileName: null,
      cvUploaded: false,
      termsOfUseAccepted: false,
      privacyAccepted: false,
      isSendButtonEnabled: false,
      sendApplicationButton: null, // Initialize the button reference,
      isLoading: false,
    };
  },
  beforeCreate() {
      this.job = this.$store.state.job
      console.log("this.job = ", this.$store.state.job)
  },
  mounted() {
    this.sendApplicationButton = this.$el.querySelector(
      ".send-application-button"
    );
    // Check if data is stored in local storage
  },
  watch: {
    email() {
      this.updateSendApplicationButtonState();
    },
    cvUploaded() {
      this.updateSendApplicationButtonState();
    },
    termsOfUseAccepted() {
      this.updateSendApplicationButtonState();
    },
    privacyAccepted() {
      this.updateSendApplicationButtonState();
    },
    isSendButtonEnabled(newVal) {
      if (this.sendApplicationButton) {
        // Update the button's disabled state
        this.sendApplicationButton.disabled = !newVal;
      }
    },
  },
  methods: {
    validateEmailInput(event) {
      const emailValue = event.target.value;
      const regex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(emailValue)) {
        this.email = emailValue;
      } else {
        this.email = null;
      }
    },
    handleTermsOfUseChange(event) {
      this.termsOfUseAccepted = event.target.checked;
    },
    handlePrivacyChange(event) {
      this.privacyAccepted = event.target.checked;
    },
    updateSendApplicationButtonState() {
      this.isSendButtonEnabled =
        this.cvUploaded &&
        this.termsOfUseAccepted &&
        this.privacyAccepted &&
        this.email;
    },
    applyNow() {
      // Open the hidden file input element using click()
      const fileInput = document.getElementById("jobApplyFile");
      fileInput.click();
      // Listen for the change event on the file input element
      fileInput.addEventListener("change", () => {
        const selectedFile = fileInput.files[0];
        if (selectedFile) {
          this.cvFile = selectedFile;
          this.selectedFileName = selectedFile.name;
          this.cvUploaded = true;
        } else {
          this.selectedFileName = null;
          this.cvUploaded = false;
        }
      });
    },
    async sendCV() {
      if (
        this.cvUploaded &&
        this.termsOfUseAccepted &&
        this.privacyAccepted &&
        this.email
      ) {
        this.isLoading = true;
        const currentDate = new Date();
        const milliseconds = currentDate.getTime();
        try {
          // Create a reference to the CV file in Firebase Storage
          const storageRef = ref(storage_cv, `cvs/${this.selectedFileName}`);
          // Upload the CV file to Firebase Storage
          await uploadBytes(storageRef, this.cvFile);
          // Get the download URL for the uploaded file
          const downloadURL = await getDownloadURL(storageRef);
          // Update the Realtime Database with the CV file information

          const cvKey = push(dbRef(external_cv_DB)).key;
          const reference_jobs_cvs = dbRef(
            external_cv_DB,
            `/jobs-cvs/${this.job.key}/${cvKey}`
          );
          const reference_cvs_jobs = dbRef(
            external_cv_DB,
            `/cvs-jobs/${cvKey}`
          );
          const entityKey = this.entity.key;
          const promises = [];
          const reference_entities_cvs = dbRef(
            external_cv_DB,
            `/entities/${entityKey}/jobs/${this.job.key}`
          );
          const p0 = update(reference_entities_cvs, {
            [cvKey]: 1,
          });
          promises.push(p0);
          const p1 = update(reference_jobs_cvs, {
            fileName: this.selectedFileName,
            downloadURL: downloadURL,
            email: this.email,
            date: milliseconds,
            entityKey,
            entityName: this.entity.name,
            entity: this.entity,
          });
          promises.push(p1);
          const p2 = update(reference_cvs_jobs, {
            [this.job.key]: 1,
            entityKey,
          });
          promises.push(p2);
          await Promise.all(promises);
          // Upload completed and database updated
          this.isLoading = false;
          alert("Application submitted successfully!");
        } catch (error) {
          alert(error);
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.job-container {
  background-color: #fff;
  padding: 30px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 50px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.job-company {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-weight: bold;
  font-size: 25px;
}

.job-title {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.job-availability {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-weight: bold;
}

.job-address {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
  font-weight: bold;
}

.job-description {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  font-weight: normal;
}

.apply-job-container {
  display: flex;
  align-content: center; /* Align flex items vertically within the container */
  align-items: center; /* Center flex items horizontally within the container */
  flex-direction: column; /* Arrange flex items vertically */
  margin: 0 auto; /* Center the container horizontally */
  padding: 10px; /* Add padding to the container */
}

.job-apply-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
}

.selected-file-name {
  display: none;
}

.file-name-label {
  margin-top: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  word-wrap: break-word;
  color: gray;
}

.send-application-container {
  margin-top: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.termsOfUse-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.termsOfUse-container label {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 5px;
  margin-top: 0px;
}

.privacy-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.privacy-container label {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 5px;
  margin-top: 0px;
}

.label-privacy {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 10px;
  margin-top: 0px;
}

.email-input {
  border: 2px solid lightgray;
  margin-top: 20px;
  border-radius: 10px;
  padding: 5px 10px;
}

.send-application-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
}

.send-application-button:disabled {
  background-color: gray;
  color: #333;
  pointer-events: none;
  cursor: not-allowed;
}

.send-application-button:enabled {
  background-color: #007bff;
  color: #fff;
  pointer-events: auto;
  cursor: pointer;
}

input[type="checkbox"] {
  margin-right: 5px;
  margin-left: 10px;
}
</style>
