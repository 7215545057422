// components/CookieBanner.vue
<template>
  <div class="cookies-banner">
    <p class="cookies-text">
      We use tracking cookies to understand how you use the product and help us
      improve it. Please accept cookies to help us improve.
    </p>
    <div>
      <button class="cookies-button" @click="acceptCookies">Accept</button>
      <button class="cookies-button" @click="declineCookies">Decline</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieBanner",
  methods: {
    acceptCookies() {
      this.$posthog.opt_in_capturing();
      this.$emit("hideBanner");
    },
    declineCookies() {
      this.$posthog.opt_out_capturing();
      this.$emit("hideBanner");
    },
  },
};
</script>

<style scoped>
.cookies-banner {
  background-color: lightgoldenrodyellow;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 150px;
}

.cookies-text {
  padding: 10px;
  text-align: center;
  color: black;
  font-size: 15px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.cookies-button {
  flex: 1;
  font-size: 15px;
  padding: 5px 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: normal;
  margin: 3px;
}
</style>
