<template>
  <div>
    <TopBar />
    <p class="company-name">{{ this.entity.name }}</p>
    <p class="open-jobs">Posizioni aperte</p>
    <div v-for="job in jobs" :key="job.key" class="job-listing">
      <p class="job-formattedDate">{{ job.formattedDate }}</p>
      <p class="job-location">{{ job.jobLocation.address }}</p>
      <p v-if="job.fullTime" class="availability">Full-time</p>
      <p v-else class="availability">Part-time</p>
      <p class="job-title">{{ job.jobTitle }}</p>
      <p class="job-description">{{ job.jobDescription }}</p>

      <button @click="applyNow(job)" class="job-apply-button">Candidati</button>
    </div>

    <div v-if="isLoading">
      <div class="loading-overlay">
        <div class="loading-spinner">
          <span class="loading-spinner-dot"></span>
          <span class="loading-spinner-dot"></span>
          <span class="loading-spinner-dot"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref as dbRef, onValue } from "firebase/database";
import {
  entities_enriched_DB,
  jobOffers_DB,
  joblocations_DB,
} from "../firebase";
import moment from "moment";
import TopBar from "./TopBar.vue";

export default {
  components: {
    TopBar,
    // MainSearchBar,
    // RouterLink,
  },
  name: "JobListing",
  props: {
    entity: {
      type: Object,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      jobs: [],
      isLoading: false,
    };
  },
  // Fetch data when component is created
  created() {
    this.fetchJobs();
  },
  methods: {
    fetchJobs() {
      this.isLoading = true;
      const companyRef = dbRef(entities_enriched_DB, `/${this.companyId}`);
      onValue(companyRef, (snapshot) => {
        const companyData = snapshot.val();
        if (companyData) {
          const jobOfferKeys = Object.keys(companyData.jobOffers);
          jobOfferKeys.forEach((jobOffer_key) => {
            const jobOfferRef = dbRef(jobOffers_DB, `/${jobOffer_key}`);
            onValue(jobOfferRef, (snap) => {
              const jobData = snap.val();
              if (jobData) {
                const placeId = jobData.placeId;
                const placeRef = dbRef(joblocations_DB, `/${placeId}`);
                onValue(placeRef, (snap) => {
                  const jobLocation = snap.val();
                  const formattedDate = moment(jobData.date * 1000).format(
                    "MMMM Do, YYYY"
                  );
                  const updatedJobData = {
                    ...jobData,
                    formattedDate,
                    jobLocation,
                  };
                  this.jobs.push(updatedJobData);
                  this.isLoading = false;
                });
              }
            });
          });
        }
      });
    },
    applyNow(job) {
      this.$router.push({
        name: "ApplyJob",
        params: {
          entity: this.entity,
          entityName: this.entity.name,
          job: job,
        },
      });

      // Open the hidden file input element using click()
      const fileInput = document.getElementById("jobApplyFile");
      fileInput.click();
      // Listen for the change event on the file input element
      fileInput.addEventListener("change", () => {
        const selectedFile = fileInput.files[0];
        console.log(job);

        // Handle the selected file
        if (selectedFile) {
          // Upload the file to your server or cloud storage
          // ...
          // Process the application
          // ...
        } else {
          console.log("No file selected");
        }
      });
    },
  },
};
</script>

<style scoped>
@import "./JobListing.css";
@import "./LoadingOverlay.css";
</style>
