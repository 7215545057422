import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';


// Import the functions you need from the SDKs you need
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAcaomNbdodCqQvI09FxMbtMaaS3GKN7ZI",
    authDomain: "talketing.firebaseapp.com",
    databaseURL: "https://talketing.firebaseio.com",
    projectId: "talketing",
    storageBucket: "talketing.appspot.com",
    messagingSenderId: "689527487588",
    appId: "1:689527487588:web:db18714234ab932bd3b371",
    measurementId: "G-8DGNVRH4XF"
};


// Initialize Firebase app

// const analytics = getAnalytics(firebaseApp);
const talketing_DB_app = initializeApp(firebaseConfig);
const talketing_DB = getDatabase(talketing_DB_app)

const storage = getStorage(talketing_DB_app, "gs://talketing.appspot.com")
const storage_cv = getStorage(talketing_DB_app, "gs://talketing-cv")

const jobOffers_DB_app = initializeApp(firebaseConfig);
const jobOffers_DB = getDatabase(jobOffers_DB_app, "https://talketing-joboffers.firebaseio.com");

const external_jobs_DB_app = initializeApp(firebaseConfig);
const external_jobs_DB = getDatabase(external_jobs_DB_app, "https://talketing-external-jobs.firebaseio.com");

const entities_enriched_DB_app = initializeApp(firebaseConfig);
const entities_enriched_DB = getDatabase(entities_enriched_DB_app, "https://talketing-entities-enriched.firebaseio.com");

const joblocations_DB_app = initializeApp(firebaseConfig);
const joblocations_DB = getDatabase(joblocations_DB_app, "https://talketing-joblocations.firebaseio.com");

const external_cv_DB_app = initializeApp(firebaseConfig);
const external_cv_DB = getDatabase(external_cv_DB_app, "https://talketing-external-cv.europe-west1.firebasedatabase.app/");

const checkout_sessions_DB_app = initializeApp(firebaseConfig);
const checkout_sessions_DB = getDatabase(checkout_sessions_DB_app, "https://stripe-checkout-sessions.europe-west1.firebasedatabase.app/");


const retail_shops_DB_app = initializeApp(firebaseConfig);
const retail_shops_DB = getDatabase(retail_shops_DB_app, "https://retail-shops.europe-west1.firebasedatabase.app/");


const retail_shops_applications_DB_app = initializeApp(firebaseConfig);
const retail_shops_applications_DB = getDatabase(retail_shops_applications_DB_app, "https://retail-shops-applications.europe-west1.firebasedatabase.app/");

export { talketing_DB, jobOffers_DB, external_jobs_DB, entities_enriched_DB, joblocations_DB, storage, storage_cv, external_cv_DB, checkout_sessions_DB, retail_shops_DB, retail_shops_applications_DB }


