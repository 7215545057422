<template>
  <div class="search-bar-container">
    <div class="text-container">
      <p class="search-bar-title">Job Search</p>
      <p class="search-bar-subtitle">powered by Google Cloud Talent Solution</p>
    </div>
    <div class="search-bar">
      <input
        v-model="searchTerm"
        type="text"
        placeholder="es. Developer in Milan"
        class="search-bar-input"
        @keydown.enter="handleSearchInput"
      />
      <button @click="handleSearchInput" class="search-bar-button">
        <i class="fas fa-search"></i> Search
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "", // Bind the input value to this data property
    };
  },
  methods: {
    handleSearchInput() {
      this.$emit("search-input-changed", this.searchTerm);
    },
    handleEnterPress() {
      this.$emit("search-enter-pressed", this.searchTerm);
    },
  },
};
</script>

<style scoped>
.search-bar-container {
  background-color: lightblue;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
}

.text-container {
  display: inline-block;
  padding: 20px;
}

.search-bar-title {
    padding: 10px;
  text-align: center;
  color: black;
  font-size: 25px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.search-bar-subtitle {
  color: gray;
  text-align: right;
  font-size: 12px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 0.5px solid #ccc;
  margin-left: 5%;
  margin-right: 5%;
  height: 50px;
  width: 1000px;
}

.search-bar-input {
  flex: 1;
  border: none;
  outline: none;
  padding-left: 10px;
}

.search-bar-icon {
  margin-left: 10px;
  color: #ccc;
}

.search-bar-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
