<template>
  <div class="product-card">
    <div class="container-images">
      <img class="company-logo-image" :src="product.logo" alt="Il tuo logo" />
      <img
        class="company-logo-image-2"
        :src="product.image"
        alt="Product image"
      />
    </div>
    <h2 class="title">{{ product.title }}</h2>
    <p class="description">{{ product.description }}</p>
    <button
      v-if="product.showButton"
      @click="chooseProduct(product)"
      class="product-button"
    >
      Seleziona
    </button>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product: Object,
  },
  methods: {
    chooseProduct(product) {
      sessionStorage.setItem("product", JSON.stringify(product));
      this.$router.push({
        name: "RetailDetails",
      });
    },
  },
};
</script>

<style scoped>
@import "./ProductCard.css";
</style>
