function convertUnixTimestampToRelativeDate(unixTimestamp) {
  // Convert Unix timestamp to milliseconds
  const timestampInMilliseconds = unixTimestamp * 1000;

  // Create a Date object
  const date = new Date(timestampInMilliseconds);

  // Get the current date
  const now = new Date();

  // Calculate the difference in seconds
  const secondsAgo = Math.floor((now - date) / 1000);

  // Define time intervals in seconds
  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  // Determine the appropriate time unit
  if (secondsAgo < minute) {
    return "just now";
  } else if (secondsAgo < hour) {
    const minutes = Math.floor(secondsAgo / minute);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (secondsAgo < day) {
    const hours = Math.floor(secondsAgo / hour);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (secondsAgo < week) {
    const days = Math.floor(secondsAgo / day);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (secondsAgo < month) {
    const weeks = Math.floor(secondsAgo / week);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (secondsAgo < year) {
    const months = Math.floor(secondsAgo / month);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(secondsAgo / year);
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
}

export { convertUnixTimestampToRelativeDate };
