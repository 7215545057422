
import Vue from 'vue'
import VueRouter from 'vue-router'

import JobsListing from '../components/JobsListing.vue'
import JobListing from '../components/JobListing.vue'
import ApplyJob from '../components/ApplyJob.vue'
import RetailProducts from '../components/RetailProducts.vue'
import RetailDetails from '../components/RetailDetails.vue'
import ContactsPage from '../components/ContactsPage.vue'
import Before_Checkout from '../components/Before_Checkout.vue'
import ListOfRetailShops from '../components/ListOfRetailShops.vue'
import DisplayRetailShop from '../components/DisplayRetailShop.vue'
import PrivacyPolicy from '../components/PrivacyPolicy.vue'
import TermsOfService from '../components/TermsOfService.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'JobsListing', 
        component: JobsListing
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy', 
        component: PrivacyPolicy
    },
    {
        path: '/terms-of-service',
        name: 'TermsOfService', 
        component: TermsOfService
    },
    {
        path: '/retail',
        name: 'RetailProducts', 
        component: RetailProducts
    },
    {
        path: '/retail-shops',
        name: 'ListOfRetailShops', 
        component: ListOfRetailShops
    },
    {
        path: '/company-details',
        name: 'RetailDetails', 
        component: RetailDetails,
    },
    {
        path: '/retail-details',
        name: 'DisplayRetailShop', 
        component: DisplayRetailShop,
        props: (route) => ({
            companyDetails: route.params.companyDetails,
            ownerDetails: route.params.ownerDetails,
            companyKey: route.params.companyKey,
        })
    },
    {
        path: '/shipping',
        name: 'Before_Checkout', 
        component: Before_Checkout,
    },
    {
        path: '/contacts',
        name: 'ContactsPage', 
        component: ContactsPage
    },
    {
        path: '/jobs/:entityName/',
        name: 'JobListing',
        component: JobListing,
        props: (route) => ({
            entity: route.params.entity,
            entityName: route.params.entityName,
            companyId: route.params.companyId,
        })
    },
    {
        path: '/jobs/companyDisplayName/apply/',
        name: 'ApplyJob',
        component: ApplyJob,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router