<template>
  <div class="bottom-bar">
    <div class="app-download-container">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Fdownload-app-logo.png?alt=media&token=4e3fe33e-6b14-40b1-b139-4b7a004be644"
        alt="Download Talketing iOS"
      />
    </div>
    <div class="bar-content">
      <div class="top-bar-content">
        <nav>
          <a href="/">Home</a>
          <a href="#">Products</a>
          <a href="#">Services</a>
          <a href="#">About Us</a>
          <a href="#">Blog</a>
          <a href="/contacts">Contact</a>
          <a href="#">FAQ</a>
          <a href="/terms-of-service">Terms of Service</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="#">Careers</a>
        </nav>
        <div class="faq-section">
          <div class="faq">
            <h4>FAQ</h4>
            <ul>
              <li><a href="#">How to order?</a></li>
              <li><a href="#">Shipping information</a></li>
              <li><a href="#">Return policy</a></li>
              <!-- Add more FAQ links as needed -->
            </ul>
          </div>
        </div>
        <div class="logo-section">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2FTransparent.png?alt=media&token=98066979-286b-4501-a2dc-a59f07579e91"
            alt="Bottom Bar Logo"
          />
          <p class="logo-section-text">Job Change Made Easy</p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="bottom-bar-content">
        <div class="copyright">
          <p>© 2021 - 2023 Talketing Ltd</p>
        </div>
        <div class="company-info-section">
          <div class="address">
            <h4>Talketing Ltd</h4>
            <p>International House, 61 Mosley Street</p>
            <p>M2 3HZ, Manchester, UK</p>
          </div>
        </div>
        <div class="social-media-section">
          <!-- Add your social media links/icons here -->
          <a href="#" class="social-icon">LinkedIn</a>
          <a href="#" class="social-icon">Instagram</a>
          <a href="#" class="social-icon">TikTok</a>
          <!-- Add more social media links/icons as needed -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomBar",
};
</script>

<style scoped>
@import "./BottomBar.css";
</style>
