<template>
  <div class="feature-card">
    <img
      class="feature-image"
      :src="feature.image"
      alt="Feature image"
    />
    <div class="feature-text-container">
      <h2 class="feature-title">{{ feature.title }}</h2>
      <p class="feature-description">{{ feature.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feature: Object,
  },
};
</script>

<style scoped>
@import "./FeatureCard.css";
</style>
