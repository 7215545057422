<template>
  <div class="description-card">
    <img
      class="description-image"
      :src="description.image"
      alt="Description image"
    />
    <div class="description-text-container">
      <h2 class="description-title">{{ description.title }}</h2>
      <p class="description-description">{{ description.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: Object,
  },
};
</script>

<style scoped>
@import "./DescriptionCard.css";
</style>
