<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      :sessionId="sessionId"
      :pk="publishableKey"
      @loading="(v) => (loading = v)"
    />
    <button
      ref="checkoutButton"
      id="checkoutButton"
      class="checkout-button"
      :disabled="!isButtonEnabled"
      :enabled="isButtonEnabled"
      @click="submit"
    >
      Checkout
    </button>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { checkout_sessions_DB } from "../firebase";
import { ref, update } from "firebase/database";

import axios from "axios";

export default {
  components: {
    StripeCheckout,
  },
  props: {
    isButtonEnabled: Boolean,
    ownerDetails: Object,
    companyDetails: Object,
    QRShippingAddress: Object,
    product: Object,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      sessionId: null,
    };
  },
  watch: {},
  methods: {
    async submit() {
      // You will be redirected to Stripe's secure checkout page
      const payload = {
        ownerDetails: this.ownerDetails,
        companyDetails: this.companyDetails,
        QRShippingAddress: this.QRShippingAddress,
        product: this.product,
      };
      try {
        console.log("payload = ", payload);
        const session = await this.createStripeCheckoutSession(payload);
        this.sessionId = session.id;
        await update(ref(checkout_sessions_DB, `/${session.id}`), payload);
        this.$refs.checkoutRef.redirectToCheckout();
      } catch (error) {
        console.log("error with generateStripeSession: ", error);
        alert(error);
      }
    },
    async createStripeCheckoutSession(payload) {
      try {
        const response = await axios.post(
          "https://us-central1-talketing.cloudfunctions.net/generateStripeSession",
          payload,
          {
            headers: {
              "Content-Type": "application/json", // Set the content type
            },
          }
        );
        const session = response.data;
        return session;
      } catch (error) {
        // Handle errors
        console.error(
          "error with createStripeCheckoutSession: ",
          error.localizedDescription
        );
        throw error;
      }
    },
  },
};
</script>

<style scoped>
@import "./StripeCheckoutButton.css";
</style>
