<template>
  <div>
    <div class="path-container">
      <router-link to="/" class="router-link-class">Home /</router-link>
      <router-link to="/retail" class="router-link-class"
        >Negozi al dettaglio /</router-link
      >
      <router-link :to="generateRoute()" class="router-link-class"
        >Lista negozi
      </router-link>
    </div>
    <MainSearchBar />
    <div>
      <div v-for="item in retails" :key="item.key" class="company-container">
        <p class="company-title">{{ item.retail.companyDetails.name }}</p>
        <p class="company-industry">
          {{
            item.retail.companyDetails.address +
            ", " +
            item.retail.companyDetails.city +
            ", " +
            item.retail.companyDetails.state +
            ", " +
            item.retail.companyDetails.zipCode
          }}
        </p>
        <button @click="goToJobListing(item)" class="view-jobs-link">
          Vedi offerte {{ Object.keys(item.retail.companyDetails).length }}
        </button>
        <!-- <router-link v-if="item.company.jobOffers" :to="`/jobs/${item.key}`" class="view-jobs-link">View Job Listings</router-link> -->
      </div>

      <div v-if="isLoading">
        <div class="loading-overlay">
          <div class="loading-spinner">
            <span class="loading-spinner-dot"></span>
            <span class="loading-spinner-dot"></span>
            <span class="loading-spinner-dot"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { retail_shops_DB } from "../firebase";
import { ref, onValue } from "firebase/database";
import MainSearchBar from "../components/MainSearchBar.vue";
// import { RouterLink } from 'vue-router';

export default {
  components: {
    MainSearchBar,
    // RouterLink,
  },
  name: "ListOfRetailsShops",
  data() {
    return {
      retails: [], // Here we will store our retails data fetched from Firebase
      isLoading: false,
      currentPath: "",
    };
  },
  mounted() {
    this.getCompanyFromUrl();
    this.fetchRetailShops();
  },
  methods: {
    fetchRetailShops() {
      this.isLoading = true;
      // Here we will add code to fetch retails from Firebase when the component is mounted
      const retailsRef = ref(retail_shops_DB, "/"); // Reference to the 'retails' node
      onValue(retailsRef, (snapshot) => {
        const retailsData = snapshot.val();
        if (retailsData) {
          this.retails = Object.keys(retailsData)
            // .filter(
            //   (key) => key in retailsData && retailsData[key].jobOffers
            // )
            .map((key) => {
              this.isLoading = false;
              return { key, retail: retailsData[key] };
            });
        }
      });
    },

    getCompanyFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const companyKey = urlParams.get("companyKey");
      if (companyKey) {
        const companyRef = ref(retail_shops_DB, `/${companyKey}`); // Reference to the 'companies' node
        onValue(companyRef, (snapshot) => {
          const companyDetails = snapshot.val();
          this.$router.push({
            name: "DisplayRetailShop",
            params: {
              companyDetails: companyDetails.companyDetails,
              ownerDetails: companyDetails.ownerDetails,
            },
          });
        });
      }
    },

    goToJobListing(item) {
      this.isLoading = true;
      const companyDetails = item.retail.companyDetails;
      const ownerDetails = item.retail.ownerDetails;
      const companyKey = item.retail.key;
      //   const retailId = item.key;
      this.$router.push({
        name: "DisplayRetailShop",
        params: {
          companyDetails,
          ownerDetails,
          companyKey,
        },
      });
    },
    generateRoute() {
      return { path: this.currentPath };
    },
  },
};
</script>

<style scoped>
@import "./ListOfRetailShops.css";
@import "./LoadingOverlay.css";
@import "./DisplayRetailShop.css";
</style>
