<!-- Pagination.vue -->

<template>
  <div class="pagination">
    <button @click="previousResults" :disabled="currentResults === maxResultsPerPage">
      &#9664;
    </button>
    <p class="pagination-text">
      {{ currentResults }} of {{ totalResults }} results
    </p>
    <button @click="nextResults" :disabled="currentResults === totalResults">
      &#9654;
    </button>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    maxResultsPerPage: {
      type: Number,
      required: true,
    },
    currentResults: {
      type: Number,
      required: true,
    },
    totalResults: {
      type: Number,
      required: true,
    },
    previousResults: {
      type: Function,
      required: true,
    },
    nextResults: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style>
/* Add your pagination styles here */
.pagination {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.pagination-text {
  margin: 0 20px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.pagination button {
  padding: 5px;
  font-size: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
</style>
