<template>
  <div>
    <TopBar />
    <div class="privacy-policy-container">
      <p class="privacy-title">Privacy Policy</p>
      <p class="privacy-subtitle">
        Your data, your privacy: Our commitment to protecting your information
      </p>
      <p class="privacy-text">
        At Talketing, we take your privacy seriously. We understand the
        importance of safeguarding your personal information and are committed
        to ensuring that your data is handled with care and responsibility.
      </p>
      <p class="privacy-text-2">
        Our Privacy Policy outlines the general rules and practices we have
        implemented to protect your data and prevent any abuse. 
        We believe in
        transparency and want you to be informed about how your information is
        collected, used, and shared when you use our services.
      </p>
    </div>
    <div class="privacy-policy-secondary-container">
      <div class="privacy-policy-text-container">
        <a
          href="https://www.iubenda.com/privacy-policy/79142758"
          class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
          title="Privacy Policy"
          >Privacy Policy</a
        >
      </div>
    </div>
    <BottomBar />
  </div>
</template>

<script>
import TopBar from "./TopBar.vue";
import BottomBar from "./BottomBar.vue";

export default {
  components: {
    TopBar,
    BottomBar,
  },
  name: "PrivacyPolicy",
  mounted() {
    // Embed the Iubenda Privacy Policy script
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    document.head.appendChild(script);
  },
};
</script>

<style scoped>
@import "./PrivacyPolicy.css";
</style>
