<template>
  <div>
    <div class="background-content">
      <div class="path-container">
        <router-link to="/" class="router-link-class">Home /</router-link>
        <router-link to="/retail" class="router-link-class"
          >Negozi al dettaglio /</router-link
        >
        <router-link to="/retail-shops" class="router-link-class"
          >Lista negozi /</router-link
        >
        <router-link :to="generateRoute()" class="router-link-class">{{
          this.companyDetails.name
        }}</router-link>
      </div>
      <div class="content-wrapper">
        <CompanyDetails :companyDetails="this.companyDetails" />
        <RetailQRCode
          :companyKey="this.companyKey"
          :companyDetails="this.companyDetails"
          :ownerDetails="this.ownerDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyDetails from "../components/CompanyDetails.vue";
import RetailQRCode from "../components/RetailQRCode.vue";

export default {
  components: {
    CompanyDetails,
    RetailQRCode,
  },
  name: "DisplayRetailShop",
  props: {
    companyKey: {
      type: String,
      required: true,
    },
    companyDetails: {
      type: Object,
      required: true,
    },
    ownerDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    console.log("displayretailshop loaded");
    return {
      currentPath: "",
    };
  },
  mounted() {
    // Update the currentPath when the component is mounted
    this.currentPath = this.$route.path;
  },
  methods: {
    generateRoute() {
      return { path: this.currentPath };
    },
  },
};
</script>

<style scoped>
@import "./DisplayRetailShop.css";
</style>
