<template>
  <div>
    <div class="section-0">
      <p class="title">Inserisci i dati aziendali</p>
      <div class="top-content">
        <div class="form-container">
          <p class="subtitle">Logo</p>
          <div class="image-container">
            <a @click="uploadImage()" class="upload-image-button">Carica</a>
            <input
              class="image-input"
              type="file"
              id="logoImage"
              accept="image/png, image/jpeg"
            />
            <img
              class="image-display"
              :src="this.companyLogo"
              alt=""
            />
          </div>
          <p class="subtitle">Nome negozio *</p>
          <input
            ref="companyName"
            type="text"
            class="input"
            id="companyName"
            placeholder="Azienda"
            required
            @input="onCompanyNameChange"
          />
          <p class="subtitle">Indirizzo *</p>
          <input
            ref="companyAddress"
            class="input"
            type="text"
            id="companyAddress"
            placeholder="Indirizzo"
            required
            @input="onCompanyAddressChange"
          />
          <div class="zip-city-container">
            <div class="title-input-container">
              <p class="subtitle">CAP *</p>
              <input
                ref="companyZipCode"
                class="input"
                type="text"
                id="companyZipCode"
                placeholder="CAP"
                required
                @input="onCompanyZipCodeChange"
              />
            </div>
            <div class="title-input-container">
              <p class="subtitle">Città *</p>
              <input
                ref="companyCity"
                class="input"
                type="text"
                id="companyCity"
                placeholder="Città"
                required
                @input="onCompanyCityChange"
              />
            </div>
          </div>
          <p class="subtitle">Provincia *</p>
          <input
            ref="companyState"
            class="input"
            type="text"
            id="companyState"
            placeholder="Provincia"
            required
            @input="onCompanyStateChange"
          />
          <ul id="address-suggestions" class="suggestions-list"></ul>
          <p class="subtitle">P.IVA</p>
          <input
            ref="companyVAT"
            type="text"
            class="input"
            id="companyVAT"
            placeholder="P.IVA"
            @input="onCompanyVatChange"
          />
          <button
            @click="next()"
            :disabled="!this.isNextButtonEnabled"
            :enabled="this.isNextButtonEnabled"
            class="next-button"
          >
            Prossimo
          </button>
        </div>
        <div class="product-container">
          <product-card :product="this.product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "./ProductCard.vue";
import { storage } from "../firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

export default {
  components: {
    ProductCard,
  },
  name: "RetailDetails",
  data() {
    return {
      companyDetails: null,
      product: null,
      isNextButtonEnabled: false,
      addressSuggestions: [],
      companyLogo: null,
      logoURL: null,
      companyName: null,
      companyAddress: null,
      companyZipCode: null,
      companyCity: null,
      companyState: null,
      companyVAT: null,
      nextButton: null,
    };
  },
  mounted() {
    this.nextButton = this.$el.querySelector(".next-button");
    this.getProductFromStorage();
    this.getCompanyDetailsFromStorage();
  },
  watch: {
    companyDetails() {
      this.fillCompanyDetails();
    },
    companyName() {
      this.enableNextButton();
    },
    companyAddress() {
      this.enableNextButton();
    },
    companyZipCode() {
      this.enableNextButton();
    },
    companyCity() {
      this.enableNextButton();
    },
    companyState() {
      this.enableNextButton();
    },
    isNextButtonEnabled(newVal) {
      this.nextButton.enabled = newVal;
    },
  },
  methods: {
    getProductFromStorage() {
      const productString = sessionStorage.getItem("product");
      if (productString) {
        let product = JSON.parse(productString);
        product.showButton = false;
        this.product = product;
      }
    },
    getCompanyDetailsFromStorage() {
      const companyDetailsString = sessionStorage.getItem("companyDetails");
      if (companyDetailsString) {
        const companyDetails = JSON.parse(companyDetailsString);
        this.companyDetails = companyDetails;
      }
    },
    fillCompanyDetails() {
      if (this.companyDetails) {
        this.companyLogo = this.companyDetails.logo;
        this.product.logo = this.companyDetails.logo;
        this.product.showButton = false;
        // name
        this.companyName = this.companyDetails.name;
        this.$refs.companyName.value = this.companyDetails.name;
        // address
        this.companyAddress = this.companyDetails.address;
        this.$refs.companyAddress.value = this.companyDetails.address;
        // zipcode
        this.companyZipCode = this.companyDetails.zipCode;
        this.$refs.companyZipCode.value = this.companyDetails.zipCode;
        // city
        this.companyCity = this.companyDetails.city;
        this.$refs.companyCity.value = this.companyDetails.city;
        // state
        this.companyState = this.companyDetails.state;
        this.$refs.companyState.value = this.companyDetails.state;
        // vat
        this.companyVAT = this.companyDetails.vat;
        this.$refs.companyVAT.value = this.companyDetails.vat;
      }
      this.enableNextButton();
    },
    async uploadImage() {
      const inputFile = document.getElementById("logoImage");
      inputFile.click();
      if (inputFile.files && inputFile.files[0]) {
        const image = inputFile.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (event) => {
          this.companyLogo = event.target.result;
          this.product.logo = event.target.result;
          this.product.showButton = false;
          const newProduct = this.product;
          this.product = newProduct;
        };
        await this.uploadOnStorage(image);
      }
    },
    async uploadOnStorage(image) {
      try {
        const path = `retail-logos/${image.name}`;
        // Create a reference to the CV file in Firebase Storage
        const storageRef = ref(storage, path);
        // Upload the CV file to Firebase Storage
        await uploadBytes(storageRef, image);
        // Get the download URL for the uploaded file
        const downloadURL = await getDownloadURL(storageRef);
        // Update the Realtime Database with the CV file information
        let product = this.product;
        product.path = path;
        product.downloadUrl = downloadURL;
        this.logoURL = downloadURL;
        sessionStorage.setItem("product", JSON.stringify(product));
      } catch (error) {
        alert(error);
      }
    },
    onCompanyNameChange(event) {
      this.companyName = event.target.value;
    },
    onCompanyAddressChange(event) {
      this.companyAddress = event.target.value;
    },
    onCompanyZipCodeChange(event) {
      this.companyZipCode = event.target.value;
    },
    onCompanyCityChange(event) {
      this.companyCity = event.target.value;
    },
    onCompanyStateChange(event) {
      this.companyState = event.target.value;
    },
    onCompanyVatChange(event) {
      this.companyVAT = event.target.value;
    },
    enableNextButton() {
      this.isNextButtonEnabled =
        this.companyName &&
        this.companyAddress &&
        this.companyZipCode &&
        this.companyCity &&
        this.companyState;
    },
    next() {
      sessionStorage.setItem("product", JSON.stringify(this.product));
      const companyDetails = {
        name: this.companyName,
        address: this.companyAddress,
        zipCode: this.companyZipCode,
        city: this.companyCity,
        state: this.companyState,
        logo: this.companyLogo,
        vat: this.companyVAT,
        logoURL: this.logoURL,
      };
      sessionStorage.setItem("companyDetails", JSON.stringify(companyDetails));
      console.log("companyDetails = ", companyDetails);
      this.$router.push({
        name: "Before_Checkout",
      });
    },
  },
};
</script>

<style scoped>
@import "./RetailDetails.css";
</style>
