<template>
  <div id="app">
    <TopBar />
    <router-view />
    <CookieBanner 
    v-if="showBanner" 
    @hideBanner="showBanner = false" />
    <BottomBar />
  </div>
</template>

<script>
import CookieBanner from "./components/CookieBanner.vue";
import TopBar from "./components/TopBar.vue";
import BottomBar from "./components/BottomBar.vue";

export default {
  name: "App",
  data: function () {
    return {
      showBanner: !(
        this.$posthog.has_opted_out_capturing() ||
        this.$posthog.has_opted_in_capturing()
      ),
    };
  },
  components: {
    TopBar,
    BottomBar,
    CookieBanner,
  },
};
</script>
