<template>
  <div class="top-bar">
    <div class="top-bar-image">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/talketing.appspot.com/o/Talketing%20logos%2Ftopbar-app-logo.png?alt=media&token=459bef6c-034a-408f-9160-f1eb75440549"
        alt="Top Bar Logo"
      />
    </div>
    <!-- <div class="top-bar-title">Talketing Jobs</div> -->
    <nav class="nav">
      <router-link class="router-link-text" to="/">
        <i class="fas fa-magnifying-glass" aria-hidden="true"></i>
        Jobs
      </router-link>
      <router-link class="router-link-text" to="/">
        <i class="fas fa-plus" aria-hidden="true"></i>
        Publish
      </router-link>
      <router-link class="router-link-text" to="/retail">
        <i class="fas fa-store" aria-hidden="true"></i>
        EasyScan
      </router-link>
      <router-link class="router-link-text" to="/contacts">
        Contact us
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TopBar",
};
</script>

<style scoped>
@import "./TopBar.css";
</style>
