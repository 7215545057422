import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/output.css";
import "./assets/tailwind.css";
import { convertUnixTimestampToRelativeDate } from "./components/dateUtils.js";
import posthogPlugin from "./plugins/posthog"; //import the plugin. 


Vue.config.productionTip = false;
Vue.prototype.$convertUnixTimestampToRelativeDate =
  convertUnixTimestampToRelativeDate;

  Vue.use(posthogPlugin);


// Initialize the store with the state from local storage
const storedState = JSON.parse(localStorage.getItem("vuex_state"));
if (storedState) {
  store.replaceState(storedState);
}

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
