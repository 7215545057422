<template>
  <div>
    <div class="privacy-policy-container">
      <p class="privacy-title">Terms of Service</p>
      <p class="privacy-subtitle">
        Ensuring Transparency and Trust in Your Recruiting Journey
      </p>
      <p class="privacy-text">
        Before embarking on your exciting journey with us, please take a moment to familiarize yourself with our Terms of Service. 
        At Talketing we prioritize transparency and trust for all our users.
      </p>
    </div>
    <div class="privacy-policy-secondary-container">
      <div class="privacy-policy-text-container">
        <a
          href="https://www.iubenda.com/terms-and-conditions/79142758"
          class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
          title="Terms of Service"
          >Terms of Service</a
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TermsOfService",
  mounted() {
    // Embed the Iubenda Privacy Policy script
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    document.head.appendChild(script);
  },
};
</script>

<style scoped>
@import "./PrivacyPolicy.css";
</style>
